import React from 'react';

export default function Tip() {
  return (
    <div
      css={{
        borderBottom: '1px solid #b2b2b2',
        borderTop: '1px solid #b2b2b2',
        margin: '80px auto',
        padding: '56px 16px',
        textAlign: 'center',
        maxWidth: '80%',
        width: '740px',
      }}
    >
      <div css={{ margin: '0 auto', maxWidth: '394px', width: '90%' }}>
        <h1
          css={{
            color: '#140a0b',
            fontSize: '24px',
            marginBottom: '24px',
          }}
        >
          Got a tip worth investigating?
        </h1>
        <p css={{ color: '#848484', fontSize: '14px', lineHeight: '23px', marginBottom: '24px' }}>
          Your information could be the missing piece to an important story. Submit your tip today and make a
          difference.
        </p>
        <a
          css={{
            backgroundColor: '#140a0b',
            borderRadius: '56px',
            color: '#fff',
            display: 'inline-block',
            fontSize: '16px',
            fontWeight: 'bold',
            lineHeight: '110%',
            padding: '20px 64px',
          }}
          href='https://tips.dailywire.com/hc/en-us/requests/new'
          rel='noreferrer'
          target='_blank'
        >
          Submit Tip
        </a>
      </div>
    </div>
  );
}
