/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';

import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import fitvids from 'fitvids';
import ReactHtmlParser from 'react-html-parser';

import { BodyText } from '@/components/shared/BodyText';
import { Episode } from '@/graphql/app';
import { useHasBeenVisible } from '@/hooks/use-visibility';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { SITE_URL } from '@/lib/constants';
import { getUtmCookieData } from '@/lib/utm_cookie';

import SubscribeCTA from './SubscribeCTA';

const PostBodyText = styled(BodyText, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'expanded',
})(({ expanded }) => ({
  margin: '0 auto',
  width: '100%',

  '> *:not(.wp-caption):not(.image):not(.ad-unit):not(.ad-wrapper):not(.in-body-articles)': {
    maxWidth: 728,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingRight: 24,
    paddingLeft: 24,
  },

  '.ad-wrapper': {
    backgroundColor: 'rgba(242, 242, 242, 0.5)',
    marginTop: '40px',
    '.ad-header': {
      color: '#a6a6a6',
      fontSize: '14px',
      letterSpacing: '0.5px',
      padding: '20px 0',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  },

  '.ad-unit': {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '100%',
    width: '100%',
    margin: '20px 0px 40px',
    minHeight: 282,
    padding: 16,
    textAlign: 'center',
  },

  '> .image, > .wp-caption': {
    textAlign: 'center',
    width: '100% !important',
  },
  img: {
    height: 'auto',
  },
  '@media (min-width: 1000px)': {
    '.ad-unit': {
      minHeight: 122,
    },
  },

  '@media (min-width: 1200px)': {
    '> .image img, > .wp-caption img': {
      maxWidth: expanded ? 1200 : 680,
      maxHeight: expanded ? 907 : '100%',
      width: 'auto ',
    },
  },
}));

const Bodytruncate = styled('div')({
  position: 'relative',
  overflow: 'hidden',

  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(to top, #fff, rgba(255,255,255,0))',
    zIndex: 10,
    height: 300,
  },
});

interface IBodyContentProps {
  showAds: boolean;
  content: string;
  email: string;
  latestEpisode: Episode;
  truncate: boolean;
  slug: string;
  accountRequired: boolean;
  preview_length: 'default' | 'large';
  postId: number;
}

const BodyContent: React.FC<IBodyContentProps> = ({
  showAds,
  content,
  email = null,
  latestEpisode,
  truncate,
  slug,
  accountRequired,
  preview_length,
  postId,
}) => {
  const [requestedAds, setRequestedAds] = React.useState(false);

  const bodyComponents = ReactHtmlParser(content);

  const readersPassCarouselRef = React.useRef<HTMLDivElement>(null);

  const readersPassCarouselVisible = useHasBeenVisible(readersPassCarouselRef);

  const adRefs = React.useRef([]);

  React.useEffect(() => {
    if (readersPassCarouselVisible) {
      const abTestData = getABTestAnalyticData();
      const utmCookieData = getUtmCookieData();

      window.__DW_Next_Bridge?.Analytics.logEvent('Post Carousel Viewed', {
        ...abTestData,
        ...utmCookieData,
        location: "In-body Reader's Pass carousel",
        url: `${SITE_URL}/${slug}`,
      });
    }
  }, [readersPassCarouselVisible, slug]);

  React.useEffect(() => {
    if (showAds && typeof window !== 'undefined') {
      (window as any).gtag('event', 'ad_requested', {
        ad_id: '4',
      });
      setRequestedAds(true);
      (window as any).tude = (window as any).tude || { cmd: [] };
      (window as any).tude?.cmd?.push(function () {
        (window as any).tude.lazyloadDivs([
          'pb-slot-incontent-1',
          'pb-slot-incontent-2',
          'pb-slot-incontent-3',
          'pb-slot-incontent-4',
        ]);
      });
    }
  }, [showAds]);

  React.useEffect(() => {
    return () => {
      if (requestedAds && typeof window !== 'undefined') {
        (window as any).tude?.destroyAds;
      }
    };
  }, [requestedAds]);

  React.useEffect(() => {
    const currentRefs = adRefs.current;
    const adHasBeenViewed = {
      'pb-slot-incontent-1': false,
      'pb-slot-incontent-2': false,
      'pb-slot-incontent-3': false,
      'pb-slot-incontent-4': false,
    };
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !adHasBeenViewed[entry.target.id]) {
          adHasBeenViewed[entry.target.id] = true;
          (window as any).gtag('event', 'ad_loaded', {
            ad_id: entry.target.id,
          });
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    );
    currentRefs.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      currentRefs.forEach((ref) => {
        if (ref) {
          observer.observe(ref);
        }
      });
    };
  }, []);

  if (truncate) {
    return (
      <>
        <Bodytruncate
          css={{
            height: preview_length === 'default' ? 300 : 1200,
          }}
        >
          {bodyComponents.map((bodyComponent) => (
            <React.Fragment key={bodyComponent.key}>{bodyComponent}</React.Fragment>
          ))}
        </Bodytruncate>
        <SubscribeCTA slug={slug} />
      </>
    );
  }

  return (
    <>
      {bodyComponents.map((bodyComponent, index) => {
        const BodyComponent = bodyComponent;
        return (
          <React.Fragment key={bodyComponent.key}>
            {index === 5 && (
              <div className='ad-wrapper' data-testid='ad-wrapper'>
                {showAds && (
                  <>
                    <h3 className='ad-header'>Advertisement</h3>
                    <div className='ad-unit' id='pb-slot-incontent-1' ref={(el) => (adRefs.current[0] = el)} />
                  </>
                )}
              </div>
            )}
            {index === 8 && (
              <div className='ad-wrapper' data-testid='ad-wrapper'>
                {showAds && (
                  <>
                    <h3 className='ad-header'>Advertisement</h3>
                    <div className='ad-unit' id='pb-slot-incontent-2' ref={(el) => (adRefs.current[1] = el)} />
                  </>
                )}
              </div>
            )}
            {/* {index === bodyComponents.length - 2 && <LatestEpisode email={email} episode={latestEpisode} />} */}
            {index === 11 && (
              <div className='ad-wrapper' data-testid='ad-wrapper'>
                {showAds && (
                  <>
                    <h3 className='ad-header'>Advertisement</h3>
                    <div className='ad-unit' id='pb-slot-incontent-3' ref={(el) => (adRefs.current[2] = el)} />
                  </>
                )}
              </div>
            )}

            {BodyComponent}

            {showAds && index === 1 && <div className='dw-mm-player-container' />}
            {bodyComponents.length - 1 === index && (
              <div className='ad-wrapper' data-testid='ad-wrapper'>
                {showAds && (
                  <>
                    <h3 className='ad-header'>Advertisement</h3>
                    <div className='ad-unit' id='pb-slot-incontent-4' ref={(el) => (adRefs.current[3] = el)} />
                  </>
                )}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

interface IBodyProps {
  content: string;
  email: string;
  latestEpisode: Episode;
  showAds: boolean;
  truncate: boolean;
  slug: string;
  accountRequired: boolean;
  expanded: boolean;
  preview_length: 'default' | 'large' | null;
  postId: number;
}

const Body: React.FC<IBodyProps> = ({
  content,
  email = null,
  latestEpisode,
  showAds,
  truncate,
  slug,
  accountRequired,
  expanded,
  preview_length,
  postId,
}) => {
  React.useLayoutEffect(() => {
    fitvids('#post-body-text');
  }, []);

  React.useLayoutEffect(() => {
    // Making sure embeded Tweets load on SSR and CSR pages
    if (typeof (window as any).twttr !== 'undefined' && (window as any).twttr.init) {
      (window as any).twttr.widgets.load(document.getElementById('post-body-text'));
    }
    // Workaround to ensure instagram embed loads correctly on SSR and CSR pages
    // We were running into a senario where the instagram script added the class
    // 'instagram-media-registered' even though embed did not loaded
    if ((window as any).instgrm && (window as any).instgrm.Embeds) {
      const registeredInstgramEmbeds = document.querySelectorAll('.instagram-media-registered');
      if (registeredInstgramEmbeds.length) {
        registeredInstgramEmbeds.forEach(function (registeredInstgramEmbed) {
          registeredInstgramEmbed.classList.replace('instagram-media-registered', 'instagram-media');
        });
      }
      (window as any).instgrm.Embeds.process();
    }
  }, []);

  return (
    <div id='post-body-text'>
      <PostBodyText expanded={expanded}>
        <BodyContent
          accountRequired={accountRequired}
          content={content}
          email={email}
          latestEpisode={latestEpisode}
          postId={postId}
          preview_length={preview_length || 'default'}
          showAds={showAds}
          slug={slug}
          truncate={truncate}
        />
      </PostBodyText>
    </div>
  );
};

export default Body;
