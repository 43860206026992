import * as React from 'react';

import { Heading } from '@/components/design-system';
import { WpCategory } from 'types/wp';

interface ICategoriesProps {
  categories: WpCategory[];
}

const Categories: React.FC<ICategoriesProps> = ({ categories }) => {
  const category = categories[0];

  return (
    <Heading
      css={(theme) => ({
        marginBottom: 12,
        color: theme.colors.red,
      })}
      variant='100'
    >
      <span
        css={(theme) => ({
          display: 'none',
          [theme.mq.tablet]: {
            display: 'inline',
          },
        })}
      >
        &mdash;{' '}
      </span>
      {category.name}
      <span
        css={(theme) => ({
          display: 'none',
          [theme.mq.tablet]: {
            display: 'inline',
          },
        })}
      >
        {' '}
        &mdash;
      </span>
    </Heading>
  );
};

export default Categories;
