/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';
import dynamic from 'next/dynamic';
import { useEpisode } from '@/request/episode';

const ElectionPlayer = dynamic(() => import('@/components/episodes/ElectionPlayer'), { ssr: false });

const ArticlesMiniPlayer: any = ({ episodeData }) => {
  const { data, mutate } = useEpisode(episodeData, episodeData?.episode?.slug);
  if (!episodeData) return null;

  const episode = data.episode;

  return (
    <div>
      <ElectionPlayer
        // @ts-ignore
        episode={episode}
        fixedOnMobile={false}
        format='video'
        fromArticle={true}
        mutateEpisode={mutate}
        shouldBePIP={true}
        startMuted={false}
      />
    </div>
  );
};

export default ArticlesMiniPlayer;
