/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';

import Script from 'next/script';

import { useAuth0 } from '@/context/auth';
import { subscriberHasPaidPlan } from '@/utils/helpers';

export const Jeeng: React.FC = () => {
  const { loading, subscriber } = useAuth0();
  const hasPaidPlan = subscriberHasPaidPlan(subscriber);

  if (loading || hasPaidPlan) return null;

  return (
    <>
      <Script src={`https://users.api.jeeng.com/users/domains/7O7WGQ28YO/sdk/configs'`} strategy='lazyOnload' />
      <Script src={`https://sdk.jeeng.com/v3.js'`} strategy='lazyOnload' />
    </>
  );
};
