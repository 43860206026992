/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React from 'react';
import dynamic from 'next/dynamic';

import { Jeeng } from '@/components/advertising';

import Insticator from '@/components/insticator';
// import { Container } from '@/components/design-system';

import shouldHideAllAds from '@/components/posts/excludeAdsPaths';
import Body from '@/components/posts/full/Body';
import PostHeader from '@/components/posts/full/PostHeader';
import { useAuth0 } from '@/context/auth';
import { Episode } from '@/graphql/app';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { SITE_URL } from '@/lib/constants';
import { getUtmCookieData } from '@/lib/utm_cookie';
import { WpPost } from '@/types/wp';
import { subscriberHasAccount } from '@/utils/helpers';

import DownloadApp from './DownloadApp';
import Tip from './Tip';

const CollectionFooter = dynamic(() => import('@/components/posts/full/CollectionFooter'), { ssr: false });
const CollectionHeader = dynamic(() => import('@/components/posts/full/CollectionHeader'), { ssr: false });
const ExclusiveContentBanner = dynamic(() => import('@/components/posts/full/ExclusiveContentBanner'), { ssr: false });
const Hotwire = dynamic(() => import('@/components/posts/full/Hotwire'), { ssr: false });
const Sharebar = dynamic(() => import('@/components/shared/Sharebar'), { ssr: false });
const Topics = dynamic(() => import('@/components/posts/full/Topics'), { ssr: false });
const UpNext = dynamic(() => import('@/components/posts/full/UpNext'), { ssr: false });

interface IPostProps {
  latestEpisode: Episode;
  post: WpPost;
}

const Post: React.FC<IPostProps> = ({ latestEpisode, post }) => {
  const { loading: userLoading, subscriber, subscriptionLoading } = useAuth0();

  const hasAccount = subscriberHasAccount(subscriber);
  const showAds = !subscriptionLoading && (!subscriber || (subscriber && subscriber.showAds)) && !shouldHideAllAds();

  // Members Only Content
  const membersOnlyContent = post?.members_only;
  const canAccessMembersOnlyContent = !userLoading && hasAccount;
  // Free Account Post
  const accountRequiredPost = post?.account_required;
  // Exclusive Content
  const exclusivePost = membersOnlyContent || accountRequiredPost;
  // Can Access Post
  const canAccessPost =
    !exclusivePost || (accountRequiredPost && hasAccount) || (membersOnlyContent && canAccessMembersOnlyContent);

  // Track Paywall Views Event in Segment
  React.useEffect(() => {
    // We wait for user loading to complete to ensure this only fires once
    // and only consider tracking on 'members_only' posts
    if (!userLoading && membersOnlyContent && !canAccessMembersOnlyContent) {
      const abTestData = getABTestAnalyticData();
      const utmCookieData = getUtmCookieData();

      window.__DW_Next_Bridge?.Analytics.logEvent('Paywall Viewed', {
        ...abTestData,
        ...utmCookieData,
        content: 'Exclusive Post',
        content_author: post.author.name,
        content_category: 'News',
        content_id: post.id,
        content_title: post.title,
        content_type: 'post',
      });
    }
  }, [userLoading, membersOnlyContent, canAccessMembersOnlyContent, accountRequiredPost, hasAccount, post]);

  const collectionPostIds = post?.collection?.posts?.map((post) => post.id);
  const collectionIndex = collectionPostIds?.indexOf(post.id);
  const collectionOrder = typeof collectionIndex !== 'undefined' && collectionIndex + 1;

  return (
    <>
      {exclusivePost && (
        <ExclusiveContentBanner
          accountRequired={accountRequiredPost}
          isLocked={!canAccessPost}
          slug={`/news/${post.slug}`}
        />
      )}

      <div
        css={{
          border: '1px solid #ACACAC',
          borderRight: 'none',
          borderLeft: 'none',
          borderTop: !exclusivePost ? 'none' : undefined,
          padding: '16px 0',
          textAlign: 'center',
        }}
      >
        <img
          css={{
            display: 'table',
            margin: 'auto',
          }}
          src='/images/logo_dark.svg'
        />
      </div>

      <Jeeng />
      <article>
        <PostHeader canAccessPost={canAccessPost} post={post} />
        {post.collection && collectionOrder && (
          <CollectionHeader collection={post.collection} collection_order={collectionOrder} layout={post.layout} />
        )}
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <Body
            accountRequired={accountRequiredPost}
            content={post.content}
            email={subscriber?.email || null}
            expanded={post.layout === 'expanded'}
            key={`post-${post.id}-body`}
            latestEpisode={latestEpisode}
            postId={post.id}
            preview_length={post.preview_length}
            showAds={showAds}
            slug={`/news/${post.slug}`}
            truncate={!canAccessPost}
          />
        </div>
        {post.collection && collectionOrder && (
          <CollectionFooter collection={post.collection} collection_order={collectionOrder} />
        )}
        {canAccessPost && (
          <>
            <div
              css={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                maxWidth: 728,
                width: '100%',
                margin: '0 auto',
                padding: '0 24px',
              }}
            >
              {post.topics && <Topics topics={post.topics} />}
              <div css={{ margin: '24px 0' }}>
                <Sharebar
                  author={post.author.name}
                  title={post.title}
                  type='Article'
                  url={`${SITE_URL}/news/${post.slug}`}
                />
              </div>
            </div>
            <div
              style={{
                width: '100%',
                maxWidth: '728px',
                margin: '0 auto',
              }}
            >
              <Insticator adsShouldDisplay={true} id={post.id} redirectURL={`/news/${post.slug}`} showCTA={true} />
            </div>
            <div css={{ margin: '48px 0' }}>
              <UpNext currentPostSlug={post.slug} email={subscriber?.email || null} />
            </div>
          </>
        )}
        <footer css={{ marginTop: 48 }}>
          <Hotwire showAds={showAds} />
          <Tip />
          <DownloadApp email={subscriber?.email || null} />
        </footer>
      </article>
    </>
  );
};

export default Post;
