import * as React from 'react';

import NextLink from 'next/link';

import { WpAuthor } from 'types/wp';

interface IAuthorsProps {
  authors: WpAuthor[];
}

const Authors: React.FC<IAuthorsProps> = ({ authors }) => {
  return (
    <span css={(theme) => ({ color: theme.colors.red })}>
      <strong>By</strong> &nbsp;
      {authors.map((author, index) => (
        <NextLink href={`/author/${author.slug}`} key={author.id}>
          <a>
            <strong css={(theme) => ({ color: theme.colors.red })}>{index ? `, ${name}` : author.name}</strong>
          </a>
        </NextLink>
      ))}
    </span>
  );
};

export default Authors;
