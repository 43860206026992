/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import Head from 'next/head';
import { ArticleJsonLd } from 'next-seo';
import { buildURL } from 'react-imgix';

import { APP_STORE_ID, SITE_URL } from '@/lib/constants';
import { stripHtml } from '@/utils/helpers';
import { WpPost } from 'types/wp';

interface IPostMetadataProps {
  post: WpPost;
}

const PostMetadata: React.FC<IPostMetadataProps> = ({ post }) => {
  const description = post.excerpt ? stripHtml(post.excerpt) : '';
  const parselyPubDate = new Date(post.date).toISOString().split('.')[0] + 'Z';
  const metaImageUrl = post.image ? buildURL(post.image.url, { w: 1200, h: 800 }) : '';
  const absUrl = `${SITE_URL}/news/${post.slug}`;

  return (
    <>
      <Head>
        <meta content='News' name='parsely-section' />
        <meta content={post.topics && post.topics.map((topic) => topic.name).join(',')} name='parsely-tags' />
        <meta content={parselyPubDate} name='parsely-pub-date' />
        <meta content={absUrl} name='parsely-link' />
        {metaImageUrl && <meta content={metaImageUrl} name='parsely-image-url' />}
        <title>{post?.title ?? ''}</title>
        <link href={absUrl} rel='canonical' />
        <meta content='website' property='og:type' />
        <meta content={description} name='description' />
        <meta content={post?.title ?? ''} property='og:title' />
        <meta content={description} property='og:description' />
        {metaImageUrl && <meta content={metaImageUrl} property='og:image' />}
        <meta content={absUrl} property='og:url' />
        <meta content={post?.title ?? ''} property='twitter:title' />
        <meta content={description} property='twitter:description' />
        <meta content='summary_large_image' name='twitter:card' />
        {metaImageUrl && <meta content={metaImageUrl} name='twitter:image' />}
        <meta content={`app-id=${APP_STORE_ID}, app-argument=${absUrl}`} name='apple-itunes-app' />
        <meta content={absUrl} property='al:ios:url' />
        <meta content={absUrl} property='al:android:url' />
        <meta content={absUrl} name='twitter:app:url:iphone' />
        <meta content={absUrl} name='twitter:app:url:ipad' />
        <meta content={absUrl} name='twitter:app:url:googleplay' />
      </Head>

      <ArticleJsonLd
        authorName={post.author.name}
        dateModified={post.modified}
        datePublished={post.date}
        description={description}
        images={[metaImageUrl]}
        publisherLogo={`${SITE_URL}/images/logo.png`}
        publisherName='The Daily Wire'
        title={post.title}
        url={absUrl}
      />
    </>
  );
};

export default PostMetadata;
