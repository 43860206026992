const excludedPaths = [
  '/news/we-have-some-of-the-covenant-shooters-writings-heres-what-they-say',
  '/news/covenant-journal-revealed-my-imaginary-penis',
  '/news/dont-miss-the-premiere-of-daily-wires-thriller-shut-in-coming-soon',
  '/news/multiple-people-shot-at-private-nashville-christian-school',
  '/news/female-shooter-kills-at-least-6-including-3-kids-in-nashville-christian-school-shooting',
  '/news/nashville-christian-school-shooter-identified-as-transgender-authorities',
  '/news/monday-afternoon-update-nashville-school-shooting-israeli-protests-zombie-drug-hits-nation',
  '/news/white-house-blames-republicans-after-nashville-christian-school-shooting',
  '/news/police-identify-victims-in-nashville-christian-school-shooting',
  '/news/trans-activists-called-for-day-of-vengeance-before-nashville-shooting',
  '/news/joe-biden-opens-with-ice-cream-jokes-before-addressing-nashville-shooting',
  '/news/legacy-media-scramble-to-recover-claim-police-misidentified-gender-of-nashville-shooter',
  '/news/not-at-all-what-one-would-expect-as-the-school-shooter-archetype-former-classmate-of-nashville-school-shooter-says-peers-shocked-by-tragic-mass-shooting',
  '/news/legacy-media-scramble-to-recover-claim-police-misidentified-gender-of-nashville-shooter',
  '/news/i-dont-want-to-be-an-only-child-older-sister-of-nashville-victim-cries-at-vigil',
  '/news/trans-activists-called-for-day-of-vengeance-before-nashville-shooting',
  '/news/graphic-bodycam-footage-shows-heroic-police-response-to-nashville-shooting',
  '/news/nashville-shooter-sent-chilling-messages-to-a-friend-moments-before-entering-christian-school',
  '/news/social-media-posts-show-mom-of-nashville-shooter-advocated-for-gun-control',
  '/news/god-please-save-us-olympic-gymnast-was-across-the-street-when-nashville-shooting-started',
  '/news/nashville-shooter-planned-to-shoot-up-mall-police-say',
  '/news/josh-hawley-calls-for-federal-hate-crime-investigation-into-shooting-at-nashville-christian-school',
  '/news/pray-for-our-brother-pastors-rally-around-covenant-senior-minister-whose-daughter-was-slain-in-nashville-shooting',
  '/news/nashville-shooter-was-receiving-treatment-for-emotional-disorder-police',
  '/news/focus-on-nashville-shooters-transgender-identity-a-distraction-says-cnn-analyst',
  '/news/biden-calls-for-republicans-to-face-pressure-claims-he-supports-2a-in-renewed-gun-control-push',
  '/news/dont-let-bidens-all-time-offensive-response-to-the-nashville-shooting-get-buried',
  '/news/biden-jokes-about-whether-or-not-christians-were-targeted-by-nashville-shooter',
  '/news/trans-group-issues-controversial-statement-after-trans-killer-targets-christian-school',
  '/news/newsweek-retracts-hideous-lie-of-a-headline-claiming-candace-owens-said-transgender-surgery-turned-nashville-shooter-into-monster',
  '/news/daily-wire-reporter-suspended-from-twitter-over-story-about-trans-day-of-vengeance-scheduled-for-same-week-as-nashville-shooting',
  '/news/god-is-a-redeemer-tennessee-governor-bill-lee-addresses-the-state-after-covenant-school-massacre',
  '/news/head-of-nashville-school-murdered-when-she-ran-toward-shooter-report',
  '/news/melissa-joan-hart-tearfully-recounts-helping-kindergartners-escape-nashville-shooting',
  '/news/fing-sick-of-the-knee-jerk-its-the-guns-megyn-kelly-rips-gun-control-advocates-after-nashville-shooting',
  '/news/fbi-probes-nashville-school-shooters-manifesto-as-pressure-mounts-for-its-release',
  '/news/twitter-suspended-accounts-who-raised-concerns-over-trans-day-of-vengeance-now-users-demand-answers',
  '/news/a-look-back-on-three-gun-control-laws-that-were-ultimately-ineffective',
  '/news/trans-day-of-vengeance-canceled-organizers-cite-threat-to-life-and-safety',
  '/news/trans-activists-slammed-for-staging-die-in-protest-hours-after-trans-identifying-shooter-killed-6',
  '/news/california-kindergarteners-will-read-about-transgender-kids-for-transgender-day-of-visibility',
  '/news/biden-proclamation-condemns-states-with-laws-targeting-transgender-youth',
  '/news/authorities-release-911-calls-made-during-nashville-christian-school-shooting',
  '/news/latest-evidence-lockdowns-were-a-disaster-child-shootings-skyrocketed-in-four-liberal-cities',
  '/news/protesters-storm-tennessee-capitol-demanding-gun-control',
  '/news/god-made-me-in-her-image-transgender-activist-claims-michael-knowles-blasphemes-christ-by-opposing-gender-ideology',
  '/news/far-left-rep-bowman-screams-cowards-gutless-at-republicans-about-guns-massie-calmly-points-one-thing-out',
  '/news/nashville-mourns-covenant-school-shooting-victims-at-citywide-vigil',
  '/news/karine-jean-pierre-dodges-on-whether-bidens-proposed-gun-ban-will-lead-to-confiscation',
  '/news/here-are-the-states-with-restrictions-on-medical-child-sex-changes',
  '/news/ex-fbi-agent-turned-nashville-councilman-recalls-horror-of-school-shooting',
  '/news/marine-corps-vet-and-commended-cop-meet-the-heroic-officers-who-took-down-the-nashville-shooter',
  '/news/ben-shapiro-breaks-down-why-big-media-blames-you-for-nashville-school-attack',
  '/news/press-secretary-for-gov-katie-hobbs-resigns-after-tweet-suggesting-transphobes-should-be-shot',
  '/news/tennessee-gop-rep-calls-for-national-religious-revival-after-nashville-shooting',
  '/news/fing-sick-of-the-knee-jerk-its-the-guns-megyn-kelly-rips-gun-control-advocates-after-nashville-shooting',
  '/news/michael-knowles-suspended-from-twitter-after-posting-bible-verse-in-response-to-nashville-school-shooting',
  '/news/nashville-christian-school-shooter-planned-massacre-months-in-advance-considered-previous-mass-murderers-police',
  '/news/white-house-hosts-roundtable-on-affirming-transgender-kids',
  '/news/they-are-under-attack-trans-movements-growing-aggression-finds-cover-under-claims-of-genocide',
  '/news/he-is-with-us-covenant-presbyterian-holds-first-sunday-worship-service-since-school-massacre',
  '/news/country-star-performs-with-drag-queens-at-cmt-award-show-pushes-gun-control',
  '/news/bill-maher-calls-for-media-blackout-on-school-shooters-its-just-going-to-inspire-the-next-one',
  '/news/another-arizona-governor-aide-out-of-job',
  '/news/nashville-preds-honor-victims-first-responders-of-christian-school-shooting',
  '/news/gov-bill-lee-to-propose-plan-to-put-armed-guard-in-every-tennessee-school',
  '/news/transgender-activists-shout-our-schools-inside-florida-capitol-building',
  '/news/navy-urges-americans-to-be-an-ally-as-biden-admin-promotes-transgenderism',
  '/news/heres-whats-going-on-with-trans-days-of-visibility-and-vengeance',
  '/news/cbs-execs-told-staffers-not-to-report-nashville-shooters-transgender-identity-report',
  '/news/leftist-protesters-arrested-as-kentucky-republicans-override-governors-veto-to-ban-child-sex-changes',
  '/news/fbi-probes-nashville-school-shooters-manifesto-as-pressure-mounts-for-its-release',
  '/news/karine-jean-pierre-our-hearts-go-out-to-the-trans-community-as-they-are-under-attack-right-now',
  '/news/black-friday-gift-guide',
];

export default function shouldHideAllAds() {
  if (typeof window === 'undefined') {
    return false;
  }
  if (excludedPaths.includes(window.location.pathname)) {
    return true;
  }

  return false;
}
