/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { FC } from 'react';

import { getABTestAnalyticData } from '@/lib/abTesting';

type DownloadAppProps = {
  email: string;
};

const DownloadApp: FC<DownloadAppProps> = ({ email = null }) => {
  const linkUrl = 'https://onelink.to/dwapp';

  const sendEventApple = () => {
    const abTestData = getABTestAnalyticData();

    window.__DW_Next_Bridge.Analytics.logEvent('Landing Page CTA Click', {
      ...abTestData,
      destination: linkUrl,
      email: email,
      label: 'Download on the App Store',
      location: 'Article Footer App Download',
    });
  };

  const sendEventGoogle = () => {
    const abTestData = getABTestAnalyticData();

    window.__DW_Next_Bridge.Analytics.logEvent('Landing Page CTA Click', {
      ...abTestData,
      destination: linkUrl,
      email: email,
      label: 'Get it on Google Play',
      location: 'Article Footer App Download',
    });
  };

  return (
    <div
      css={(theme) => ({
        margin: '0 auto 42px',
        maxWidth: '500px',
        textAlign: 'center',
        width: '100%',
        [theme.mq.desktop]: {
          display: 'none',
        },
      })}
    >
      <img alt='Download Daily Wire Plus' src='/images/articles/mobile-app-preview.jpg' style={{ width: '100%' }} />
      <div
        style={{
          color: '#0a0909',
          paddingTop: '24px',
        }}
      >
        <h3 style={{ fontSize: '14px', lineHeight: '120%', marginBottom: '4px' }}>Don&apos;t miss anything</h3>
        <h2 style={{ fontSize: '32px', letterSpacing: '-1.2px', marginBottom: '16px' }}>Download our App</h2>
        <p style={{ fontSize: '16px', lineHeight: '170%', marginBottom: '16px' }}>
          Stay up-to-date on the latest
          <br />
          news, podcasts, and more.
        </p>
      </div>
      <div>
        <a href={linkUrl} onClick={sendEventApple}>
          <img
            alt='Download on the app store'
            src='/images/articles/app-download/app-store.png'
            style={{ margin: '0 7px', width: '117px' }}
          />
        </a>
        <a href={linkUrl} onClick={sendEventGoogle}>
          <img
            alt='Get it on Google Play'
            src='/images/articles/app-download/google-play.png'
            style={{ margin: '0 7px', width: '117px' }}
          />
        </a>
      </div>
    </div>
  );
};

export default DownloadApp;
