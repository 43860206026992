import * as React from 'react';

import { Container, Heading } from '@/components/design-system';
import { hangerKiller } from '@/utils/helpers';
import { WpPost } from 'types/wp';

import Byline from './Byline';
import Categories from './Categories';
import FeaturedImage from './FeaturedImage';

interface IPostHeaderProps {
  post: WpPost;
  canAccessPost?: boolean;
}

const PostHeader: React.FC<IPostHeaderProps> = ({ post, canAccessPost }) => {
  if (post.layout === 'expanded') {
    return (
      <div>
        <div
          css={(theme) => ({
            [theme.mq.desktop]: {
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              borderBottom: '1px solid' + theme.colors.gray20,
              marginBottom: 16,
            },
          })}
        >
          {post.image && <FeaturedImage post={post} />}
          <div
            css={(theme) => ({
              marginTop: 24,
              [theme.mq.desktop]: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              },
            })}
          >
            <div css={{ maxWidth: 728, margin: '0 auto', padding: '0 24px' }}>
              {post.categories && <Categories categories={post.categories} />}
              {!post.categories && (
                <div
                  css={(theme) => ({
                    display: 'none',
                    [theme.mq.desktop]: {
                      marginBottom: 16,
                      display: 'block',
                    },
                  })}
                >
                  <img alt='flag' src='/svg/flags/dark.svg' />
                </div>
              )}
              <Heading as='h1' dangerouslySetInnerHTML={{ __html: hangerKiller(post.title) }} variant='700' />
              {post.subhead && (
                <Heading as='p' css={{ marginTop: 16 }} variant='300'>
                  {post.subhead}
                </Heading>
              )}
            </div>
          </div>
        </div>
        <Byline canAccessPost={canAccessPost} post={post} />
      </div>
    );
  }

  return (
    <Container variant='md'>
      <header
        css={(theme) => ({
          margin: '32px auto 24px',
          display: 'flex',
          flexDirection: 'column',

          [theme.mq.tablet]: {
            alignItems: 'center',
            textAlign: 'center',
          },
        })}
      >
        {post.categories && <Categories categories={post.categories} />}
        <Heading as='h1' dangerouslySetInnerHTML={{ __html: hangerKiller(post.title) }} variant='700' />
        {post.subhead && (
          <Heading as='p' css={{ marginTop: 16 }} variant='300'>
            {post.subhead}
          </Heading>
        )}
        <Byline post={post} />
      </header>
      <FeaturedImage post={post} />
    </Container>
  );
};

export default PostHeader;
