import * as React from 'react';

import { Text } from '@/components/design-system';
import Sharebar from '@/components/shared/Sharebar';
import { SITE_URL } from '@/lib/constants';
import { WpPost } from 'types/wp';

import Authors from './Authors';
import Pubdate from './Pubdate';

interface IBylineProps {
  post: WpPost;
  canAccessPost?: boolean;
}

const Byline: React.FC<IBylineProps> = ({ post, canAccessPost }) => {
  if (post.layout === 'expanded') {
    return (
      <div
        css={(theme) => ({
          maxWidth: 728,
          margin: '16px auto 0',
          padding: '0 24px',

          [theme.mq.desktop]: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 48,
          },
        })}
      >
        <div>
          <Text as='div' css={(theme) => ({ color: theme.colors.gray60 })} variant='200'>
            <Authors authors={[post.author]} />
          </Text>
          <Text
            as='div'
            css={(theme) => ({
              color: theme.colors.gray80,
              margin: '12px 0',
              [theme.mq.desktop]: {
                margin: '4px 0 0',
              },
            })}
            variant='200'
          >
            DailyWire.com
          </Text>
        </div>
        <Sharebar
          author={post.author.name}
          canAccessPost={canAccessPost}
          id={post.id}
          insticator={true}
          title={post.title}
          type='Article'
          url={`${SITE_URL}/news/${post.slug}/`}
        />
      </div>
    );
  }

  return (
    <div
      css={(theme) => ({
        marginTop: 16,

        '> span': {
          display: 'none',
        },

        [theme.mq.tablet]: {
          display: 'flex',
          alignItems: 'center',
          '> span': {
            display: 'block',
            margin: '0 8px',
            color: theme.colors.gray20,
            fontSize: theme.fontSizes['0'],
          },
        },
      })}
    >
      <Text as='div' css={(theme) => ({ color: theme.colors.gray60 })} variant='200'>
        <Authors authors={[post.author]} />
      </Text>
      <span>&bull;</span>
      <Text
        as='div'
        css={(theme) => ({
          color: theme.colors.gray80,
          margin: '12px 0',
          [theme.mq.tablet]: {
            margin: 0,
          },
        })}
        variant='200'
      >
        <Pubdate publishedAt={post.date} />
        &nbsp; &nbsp;DailyWire.com
      </Text>
      <span>&bull;</span>
      <Sharebar
        author={post.author.name}
        canAccessPost={canAccessPost}
        id={post.id}
        insticator={true}
        title={post.title}
        type='Article'
        url={`${SITE_URL}/news/${post.slug}`}
      />
    </div>
  );
};

export default Byline;
