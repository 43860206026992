import React from 'react';

import Script from 'next/script';

type areanaIMProps = {
  content: string;
};

export const ArenaIMScript: React.FC<areanaIMProps> = ({ content }) => {
  // Regex to find the script tag with the ID after 'e='
  const regex = /https:\/\/go\.arena\.im\/public\/js\/arenalib\.js\?p=daily-wire(?:&|&#038;)e=([a-zA-Z0-9]+)/;

  // Find the match and extract the ID
  const match = content.match(regex);
  let arenaID = null;
  if (match) {
    arenaID = match[1]; // Extract the ID from the match
  }

  if (arenaID) {
    return (
      <Script
        src={`https://go.arena.im/public/js/arenalib.js?p=daily-wire&e=${arenaID}`}
        strategy='beforeInteractive'
      />
    );
  }

  return null;
};
