import * as React from 'react';

import { imgixLoader } from '@delicious-simplicity/next-image-imgix-loader';
import Image from 'next/image';

import { Text } from '@/components/design-system';
import { WpPost } from 'types/wp';

interface IFeaturedImageProps {
  post: WpPost;
}

const FeaturedImage: React.FC<IFeaturedImageProps> = ({ post }) => {
  if (!post.image) return null;

  const membersOnly = post?.members_only;

  if (post.layout === 'expanded') {
    return (
      <figure
        css={(theme) => ({
          lineHeight: 0,
          position: 'relative',
          // objectFit: 'cover',
          // width: '100%',
          paddingBottom: '125%',
          [theme.mq.desktop]: {
            height: `calc(100vh - ${membersOnly ? 125 : 80}px)`,
          },
        })}
      >
        <Image
          alt={post.image.alt}
          height={2125}
          layout='fill'
          loader={(props) => imgixLoader(props, { fit: 'crop', ar: '4:5', crop: 'faces' })}
          objectFit='cover'
          priority={true}
          src={post.image.url}
          title={post.image.title}
          width={1700}
        />
        {post.image.caption && (
          <figcaption
            css={(theme) => ({
              margin: '8px 24px 0',
              color: theme.colors.gray60,
              position: 'absolute',
              bottom: 0,
              left: '-1.5rem',
              background: 'rgba(255,255,255,.5)',
              padding: '.5rem',

              [theme.mq.desktop]: {
                position: 'absolute',
                margin: 0,
                bottom: -32,
                left: 8,
              },
            })}
          >
            <Text as='div' variant='100'>
              {post.image.caption.replace(/<[^>]*>/g, '')}
            </Text>
          </figcaption>
        )}
      </figure>
    );
  }

  return (
    <figure
      css={{
        position: 'relative',
        margin: '0 auto',
        maxWidth: 970,
        lineHeight: 0,
        overflow: 'hidden',
      }}
      key={`post-${post.id}-image`}
    >
      <Image
        alt={post.image.alt}
        height={545}
        loader={(props) => imgixLoader(props, { fit: 'crop', ar: '16:9' })}
        priority={true}
        src={post.image.url}
        title={post.image.title}
        width={970}
      />
      {post.image.caption && (
        <figcaption
          css={(theme) => ({
            marginTop: 8,

            [theme.mq.tablet]: {
              textAlign: 'right',
            },
          })}
        >
          <Text
            css={(theme) => ({
              color: theme.colors.gray60,
            })}
            variant='100'
          >
            {post.image.caption.replace(/<[^>]*>/g, '')}
          </Text>
        </figcaption>
      )}
    </figure>
  );
};

export default FeaturedImage;
