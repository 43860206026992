/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';

import { useAuth0 } from '@/context/auth';

import { sendClickEvent } from './utils';

import styles from './subscribeCTA.module.css';

interface ISubscribeCTAProps {
  slug: string;
  // postId?: number;
}

const SubscribeCTA: React.FC<ISubscribeCTAProps> = ({ slug }) => {
  const { loginWithRedirect, subscriber } = useAuth0();

  return (
    <div className={styles.subscribeCTA} id='payed-article-paywall'>
      <img
        alt='Create Free Account'
        src='https://dailywireplus-v2.imgix.net/images/dailywire.com/freeAccountGateArticle.png'
      />
      <p>Continue reading this exclusive article and join the conversation, plus watch free videos on DW+</p>
      <button
        className={styles.freeAccountButton}
        data-paywall-action-type='read:create-free-account'
        data-testid='continue-reading-button-free'
        onClick={() => {
          sendClickEvent(subscriber?.email || null, 'Create Free Account', 'Read More');
          loginWithRedirect({
            initialScreen: 'signUp',
            pageIntroduction: 'subscribe',
            appState: {
              targetUrl: slug,
            },
          });
        }}
        type='button'
      >
        Create Free Account
      </button>

      <p>
        Already a member?{' '}
        <button
          className={styles.loginButton}
          data-paywall-action-type='read:login'
          onClick={(event) => {
            event.preventDefault();
            loginWithRedirect({
              appState: { targetUrl: window.location.pathname },
            });
          }}
        >
          Log in
        </button>
      </p>
    </div>
  );
};

export default SubscribeCTA;
