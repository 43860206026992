/* eslint-disable import/no-unresolved */
import * as React from 'react';

import { differenceInDays } from 'date-fns';
import { GetStaticPaths, GetStaticProps } from 'next';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import Script from 'next/script';

import BreadCrumbs from '@/components/breadcrumbs/Breadcrumbs';
import ArticlesMiniPlayer from '@/components/homepage/ArticlesMiniPlayer';
import { ArenaIMScript } from '@/components/posts/arenaIMScript';
import Post from '@/components/posts/full';
import PostMetadata from '@/components/posts/full/PostMetadata';
import Main from '@/components/shared/Main';
import { useAuth0 } from '@/context/auth';
import { appApi, Episode } from '@/graphql/app';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { ALL_ACCESS_LIVE_SHOW_SLUG, SITE_URL } from '@/lib/constants';
import { filterMemberExclusiveEpisodes } from '@/lib/memberExclusive';
import { getPageOverride } from '@/lib/pageOverrides';
import { getUtmCookieData } from '@/lib/utm_cookie';
import { getFullPost, getRecentPosts } from '@/lib/wordpress';
import { WpPost } from '@/types/wp';
interface IPostPageProps {
  latestEpisodes: Episode[];
  post: WpPost;
  episodeData: any;
}

const PostPage: React.FC<IPostPageProps> = ({ latestEpisodes, episodeData, post }) => {
  const router = useRouter();
  const { isAuthenticated, subscriber } = useAuth0();

  // Setting RevContent UUID in session storage
  // This is used fot tracking conversions from RevContent Ads
  React.useEffect(() => {
    const rc_uuid = router?.query?.rc_uuid;
    if (rc_uuid) {
      sessionStorage.setItem('rc_uuid', rc_uuid.toString());
    }
  }, [router]);

  // Segment Analytics
  React.useEffect(() => {
    if (post) {
      const abTestData = getABTestAnalyticData();
      const utmCookieData = getUtmCookieData();
      const { topStoryPosition = null } = router.query;

      if (topStoryPosition) {
        window.__DW_Next_Bridge?.Analytics.logEvent('Segment Page', {
          ...abTestData,
          ...utmCookieData,
          title: post.title,
          author: post.author.name,
          keywords: post.topics ? post.topics.map((topic) => topic.name) : null,
          topStoryPosition: topStoryPosition,
          referrer: '/',
        });
        window.__DW_Next_Bridge?.Analytics.logEvent('Article Viewed', {
          ...abTestData,
          ...utmCookieData,
          title: post.title,
          url: `${SITE_URL}/news/${post.slug}`,
          author: post.author.name,
          category: post.topics ? post.topics.map((topic) => topic.name) : null,
          topStoryPosition: topStoryPosition,
          referrer: '/',
        });
      } else {
        window.__DW_Next_Bridge?.Analytics.logEvent('Segment Page', {
          ...abTestData,
          ...utmCookieData,
          title: post.title,
          author: post.author.name,
          keywords: post.topics ? post.topics.map((topic) => topic.name) : null,
        });
        window.__DW_Next_Bridge?.Analytics.logEvent('Article Viewed', {
          ...abTestData,
          ...utmCookieData,
          title: post.title,
          url: `${SITE_URL}/news/${post.slug}`,
          author: post.author.name,
          category: post.topics ? post.topics.map((topic) => topic.name) : null,
        });
      }
    }
  }, [post, router.query]);

  const latestEpisodesFiltered = filterMemberExclusiveEpisodes(latestEpisodes, isAuthenticated, subscriber) || [];

  return (
    <>
      {/* Prebid Scripts */}
      <Script
        className='optanon-category-C0004'
        dangerouslySetInnerHTML={{
          __html: `
              var tude = window.tude || { cmd: [] };
              tude.cmd.push(function() {
                tude.setPageTargeting();
              });
          `,
        }}
        data-cfasync='false'
        id='prebid-page-targeting'
        strategy='lazyOnload'
        type='text/plain'
      />
      <Script src='https://dn0qt3r0xannq.cloudfront.net/dailywire-EsNGlBB2ov/default/prebid-load.js' />
      {/* CloudFront Scripts */}
      <Script src='//cadmus.script.ac/d8yw4927nnimu/script.js' />
      {/* Social Network Scripts */}
      <Script src='https://platform.twitter.com/widgets.js' strategy='lazyOnload' />
      <Script src='https://www.instagram.com/embed.js' strategy='lazyOnload' />
      <ArenaIMScript content={post.content} />
      <Main bg='white'>
        <PostMetadata post={post} />
        <Post latestEpisode={latestEpisodesFiltered[0] || null} post={post} />
        <BreadCrumbs
          links={[
            <NextLink href='/'>The Daily Wire</NextLink>,
            <NextLink href='/read'>Read</NextLink>,
            <>{post.title}</>,
          ]}
        />
      </Main>
      <div>
        <ArticlesMiniPlayer episodeData={episodeData} />
      </div>
    </>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  const slug = context.params?.slug as string;
  const episodeData = null;

  // Check for an active page override for the episode
  // const pageOverride = await getPageOverride('dw_home_page');
  // if (pageOverride && pageOverride.isActive && pageOverride.episode) {
  //   episodeData = await appApi.getEpisodeBySlug({ slug: pageOverride.episode.slug });
  // }
  // Fetch the post and latest episodes concurrently
  const [post, latestEpisodesData] = await Promise.all([getFullPost(slug), appApi.getEpisodes({ first: 6 })]);

  // Return 404 if post is missing or has no date
  if (!post || !post.date) {
    return {
      notFound: true,
      revalidate: 600,
    };
  }

  const daysOld = differenceInDays(new Date(), new Date(post.date));

  // Filter out All Access Live episodes from the latest episodes
  const latestEpisodesFiltered = latestEpisodesData.episodes.filter(
    (episode) => episode?.show.slug !== ALL_ACCESS_LIVE_SHOW_SLUG,
  );

  return {
    props: {
      latestEpisodes: latestEpisodesFiltered,
      episodeData,
      post,
    },
    revalidate: daysOld <= 3 ? 300 : 21600, // 5 mins for recent posts, else 6 hrs
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  const posts = await getRecentPosts(50);
  const paths = posts.map((post) => ({ params: { slug: post.slug } }));

  return { paths, fallback: 'blocking' };
};

export default PostPage;
