import * as React from 'react';

import format from 'date-fns/format';
// eslint-disable-next-line import/order
import { zonedTimeToUtc } from 'date-fns-tz';

interface IPubdateProps {
  publishedAt: string;
}

const Pubdate: React.FC<IPubdateProps> = ({ publishedAt }) => {
  const localDate = zonedTimeToUtc(publishedAt, 'America/New_York');

  return (
    <time dateTime={format(new Date(localDate), 'LLL d, yyyy')}>{format(new Date(localDate), 'LLL d, yyyy')}</time>
  );
};

export default Pubdate;
