import * as React from 'react';

import cn from 'classnames';
import NextImage from 'next/image';
import Script from 'next/script';

import { ButtonLink, Heading, Text } from '@/components/design-system';
import { SITE_URL } from '@/lib/constants';

import s from './WidgetExample.module.css';

interface IWidgetExample {
  id: number;
  kicker: string;
  title: string;
  specDimension: string;
  specQuantity: string;
  specAdvertisement: string;
  description: string;
  imageUrl: string;
  scriptId: string;
  widgetId: string;
  type: string;
}

export const WidgetExample = React.memo<IWidgetExample>(
  ({
    id,
    description,
    kicker,
    title,
    specDimension,
    specQuantity,
    specAdvertisement,
    imageUrl,
    scriptId,
    widgetId,
    type,
  }) => {
    return (
      <>
        <Script
          dangerouslySetInnerHTML={{
            __html: `var hotwire = window.hotwire || { cmd: [] };
          hotwire.cmd.push(function() {
            hotwire.init({
              selector: '#${scriptId}',
              widget: '${widgetId}',
            });
          });`,
          }}
          data-cfasync='false'
          id={scriptId}
          strategy='lazyOnload'
        />
        <section className={s.root}>
          <div className={s.top}>
            <div className={s.content}>
              <header>
                <Heading as='div' className={s.number} variant='500'>
                  <span>{id}</span>
                </Heading>
                <Heading as='h3' variant='100'>
                  {kicker}
                </Heading>
                <Heading className={s.title} variant='600'>
                  {title}
                </Heading>
              </header>
              <Heading as='h4' variant='300'>
                Specifications
              </Heading>
              <Text as='ul'>
                <li>{specDimension}</li>
                <li>{specQuantity}</li>
                <li>{specAdvertisement}</li>
              </Text>
              <div className={s.description}>{description}</div>
              <ButtonLink href={`${SITE_URL}/advertise/widgets/sample-article`} variant='secondary'>
                Sample Article
              </ButtonLink>
            </div>
            <NextImage alt={title} className={s.img} height='516' src={imageUrl} width='416' />
          </div>
          <div className={s.bottom}>
            <Heading as='h4' variant='200'>
              Live Widget
            </Heading>
            <div
              className={cn(s.widget, {
                [s.sm]: type === 'rightrail',
                [s.lg]: type === 'incontent',
              })}
            >
              <div id={scriptId} />
            </div>
          </div>
        </section>
      </>
    );
  },
);

WidgetExample.displayName = 'WidgetExample';
